import { useTranslation } from 'react-i18next'
import { Link, isRouteErrorResponse } from 'react-router'
import { Header } from '~/components/Header'
import { GenericErrorBoundary } from '~/errors/error-boundary'
import i18n from '~/utils/i18next.server'

import { Container } from '~/components/Container'
import type { Route } from './+types/$'

export const meta = ({ error }: Route.MetaArgs) => {
  if (isRouteErrorResponse(error)) {
    return [{ title: `LoungePair - 404 ${error.statusText}!` }]
  }
  return [{ title: 'LoungePair - 404 Not Found!' }]
}

export const handle = {
  getSitemapEntries: () => {
    return null
  },
}

export async function loader({ request }: Route.LoaderArgs) {
  const t = await i18n.getFixedT(request)
  const statusText = t('errors.404.title')
  throw new Response('Not found', { status: 404, statusText })
}

export default function NotFound() {
  // Due to the loader, this component will never be rendered,
  // but as a good practice, ErrorBoundary will be returned.
  return <ErrorBoundary />
}

export function ErrorBoundary() {
  const { t } = useTranslation()
  return (
    <GenericErrorBoundary
      statusHandlers={{
        404: () => (
          <Container containerClassName="w-screen h-full">
            <div className="text-center">
              <p className="text-base font-semibold text-blue-600">404</p>
              <h1 className="mt-4 text-3xl font-bold tracking-tight text-stone-900 sm:text-5xl">
                {t('errors.404.title')}
              </h1>
              <p className="mt-6 text-xl leading-7 text-stone-600">
                {t('errors.404.message')}
              </p>

              <div className="flex items-center justify-center mt-10 gap-x-6">
                <Link
                  to="/directory/"
                  className="rounded-md no-underline bg-blue-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                >
                  {t('errors.visitTheDirectory')}
                </Link>
                <a
                  href="https://helpdesk.loungepair.com/help/3983236506"
                  className="text-sm font-semibold no-underline text-stone-900"
                >
                  {t('errors.contactSupport')}{' '}
                  <span aria-hidden="true">&rarr;</span>
                </a>
              </div>
            </div>
          </Container>
        ),
      }}
    />
  )
}
